import { Controller } from "stimulus"

function queryUrl(query) {
  let url = new URL(location.href);
  url.searchParams.set("q", query);
  url.searchParams.set("partial", "1");
  return url;
}

export default class extends Controller {
  static targets = ["input", "results"];

  debounceTimeout = null;

  connect() {
    console.log("Hello", this.resultsTarget);
  }

  inputChanged(ev) {
    let query = this.inputTarget.value;

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
    }

    let doQuery = () => {
      fetch(queryUrl(query))
        .then(resp => {
          if (resp.status === 200) {
            return resp.text();
          } else {
            return Promise.reject(new Error(`expected 200 response, got ${resp.status} ${resp.statusText}`));
          }
        })
        .then(html => {
          this.resultsTarget.innerHTML = html;
        })
        .catch(err => {
          console.error("filter-table: could not query", err);
        });
    };

    this.debounceTimeout = setTimeout(doQuery, 200);
  }
}
